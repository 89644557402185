/* You can add global styles to this file, and also import other style files */
:root{
    --button-border-radius: 10px;
    --login-button-border-radius: 8px;
    --color-border: #CBD5E1;
    --color-primary-500: #F97316;
    --color-text: #191f29;
    --colo-gray:rgba(100, 116, 139, 1);
        --color-primary-400: #FB923C;
        --color-white-1: #F8FAFC;
        --color-white-2: #F8FAFC;
        /* --side-nav-width: 297px; */
        --side-nav-width: 260px;
    --tab-border-radius: 7px
}
body {
    margin: 0;
    padding: 0;
    font-size: 14px;
    background-color: var(--color-white-2);
}
a, a:hover{
    text-decoration: none;
}
ul, li {
    padding: 0;
    list-style: none;
}
.theme-color {
    color: var(--color-primary-500);
}
.theme-border {
    border: 2px solid var(--color-primary-500);
}
.company-icon {
    height: 50px;
    width: 180px;
}
button{
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    opacity: 0.8;
}
button:hover{
    opacity: 1;
}
button:disabled {
    opacity: 0.4 !important;
    cursor: not-allowed !important;
}
button:focus {
    outline: none;
}
.component-container {
    padding: 20px 20px;
    margin-bottom: 10px;
    max-width: 100%;
    overflow-x: auto;
}
.page-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
}
.btn-theme {
    color: #ffffff;
    background-color: var(--color-primary-500);
}
.btn-theme:hover{
    color: #ffffff;
}
.btn-theme-outline {
    border: 1px solid var(--color-primary-500);
    color: var(--color-primary-500);
}
.btn-theme-outline:hover {
    border: 1px solid var(--color-primary-500);
    color: var(--color-primary-500);
} 
.service_policies {
    background-color:#F8FAFC;
}
.btn.rounded {
    border-radius: 20px !important;
}
.login-card-title {
    font-weight: 600;
    font-size: 26px;
    line-height: 36px;
}

.login-card-subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.t-c {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    margin-top: 25px;
}
input:focus-visible {
    border: 1px solid var(--color-border);
}
.form-field-error-container {
    width: inherit;
    padding: 2px;
    align-items: start;
    display: block;
    text-align: start;
}

.form-field-error {
    color: red;
    font-size: 12px;
    text-align: right;
}
img {
    max-width: 100%;
}
.text-end {
    text-align: right;
}
.font-gray{
    color: var(--colo-gray);
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent; }
::-webkit-scrollbar-thumb, ::-webkit-scrollbar {
    width: 6px;
    border-radius: 13px; }
::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 0 10px;
            box-shadow: inset 0 0 0 10px;
    background-color: #ffffff; }

.common-card{
    border: 1px solid var(--color-border);
    border-radius: var(--button-border-radius);
    padding: 25px;
    background-color: #fff;
    
}
.back-button{
    cursor: pointer;
}

.custom-tabs {
    display: flex;
    margin-bottom: 20px;
}

.tab-button {
    background-color: #fff;
    color: var(--color-text);
    margin-right: 20px;
    padding: 8px 22px;
    border: 1px solid var(--color-border);
    border-radius: var(--tab-border-radius);
}

.tab-button:hover,
.tab-button:focus,
.active {
    background-color: var(--color-primary-500);
    color: var(--color-white-2);
    border: none;
}